import React, { useEffect, useState } from 'react';
import Spinner from '../../shared/Spinner';
import Paginator from '../../shared/Paginator';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { confirm } from "../../shared/Confirmation";
import Config from '../../config/config';
import useApi from '../../hooks/UseApi';
import { Trash, PencilSquare, PlusLg, ArrowRepeat } from 'react-bootstrap-icons';
import useAuthentication from '../../auth/useAuthentication';

const Rows = (props) => {
  return (
    <>
      {props.data.map((item) => {
        return <tr className="" onClick={ (e) => { props.rowActionHandler(e, 'view', item) } } key={item.id}>
          <td className='text-nowrap'>
            <a href="#!" onClick={(e) => props.rowActionHandler(e, 'delete', item) }><Trash /></a>
            <a className="ml-2" href="#!" onClick={(e) => props.rowActionHandler(e, 'edit', item) }><PencilSquare /></a>
          </td>

          <td>{ item.machine }</td>
          <td>{ item.code }</td>
          {/* <td>{ item.code.replace(new RegExp(`^${context.user.machines.filter(m => m.id === item.machine).code}`), '') }</td> */}

          <td>{ item.name }</td>
          {/* <td>{ item.description }</td> */}
          <td>$ { item.price }</td>
          <td>{ item.stock }</td>
          <td>{ item.min_stock }</td>
          <td>{ item.slot }</td>
          {/* <td>{ item.image && <img src={ Config.API.BASE_URI + 'images/' + item.image } className="img-fluid" style={{width: '200px'}} />}</td> */}
          <td>{ item.image && <img src={ item.image } className="img x-img-fluid" style={{objectFit: 'cover', width: '80px'}} />}</td>

        </tr>
      })}
    </>
  )
};

const Products = (props) => {

  const [ data, setData ] = useState();
  const { get, del, updating } = useApi();
  const [ page, setPage ] = useState(1);
  const [ total, setTotal ] = useState(0);
  const [ search, setSearch ] = useState('');
  const navigate = useNavigate();
  const context = useAuthentication();

  const rowActionHandler = async (e, action, item) => {
    e.preventDefault();
    e.stopPropagation();
    if (action === 'edit') {
      navigate('/products-edit/' + item.id);
    } else if (action === 'delete') {
      var result = await confirm("Está seguro de eliminar el registro?");
      if (result) {
        del('products/' + item.id).then(() => {
          getData();
        }).catch(err => {
          console.log(err)
        });
      }
    }
  };



  const getData = () => {
    // console.log(context.user.machines)
    let options = { page : page };
    get('products', options).then(r => {
      setTotal(parseInt(r.headers['x-paging-total-pages']));
      r.data.forEach(item => {
        const machineCode = context.user.machines.filter(m => m.id === item.machine)[0].code;
        item.code = item.code.replace(new RegExp(`^${machineCode}`), '');
      });
      setData(r.data);
    });
  };
  useEffect(getData, [ props.location, search, page, context.user?.selectedMachine.id ]);


  const onPageChange = (page) => {
    setPage(page);
  };


  const clearSearchHandler = () => {
    setPage(1);
    setSearch('');
  };

  const searchHandler = (search) => {
    setSearch(search);
  };

  const doSyncAll = () => {
    get('products/syncProducts').then(r => {
      getData();
    });
  };
  const doSyncProducts = () => {
    get('products/sync').then(r => {
      getData();
    });
  };


  if (!context.user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="card">
      <div className="card-body">
        <br/>
        <div className="row">
          <div className="col-8">
            {/* <Search search={search} setSearch={setSearch} searchHandler={searchHandler} clearSearchHandler={clearSearchHandler} /> */}
          </div>
          <div className="col-12 text-end">
            <Link className="btn btn-danger btn-sm me-2" onClick={e => doSyncAll() }><ArrowRepeat className='me-1'/>Sincronizar Productos</Link>
            <Link className="btn btn-primary btn-sm me-2" onClick={e => doSyncProducts() }><ArrowRepeat className='me-1'/>Sincronizar Stocks</Link>
            <Link className="btn btn-primary btn-sm" to={ '/products-edit' }><PlusLg className='me-1'/>Nuevo</Link>
          </div>
        </div>

        { (!data || updating) ? <Spinner /> :
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Máquina</th>
                <th>Código</th>
                <th>Nombre</th>
                {/* <th>Descripcion</th> */}
                <th>Precio</th>
                <th>Stock</th>
                <th>Stock Min.</th>
                <th>Slot</th>
                <th>Imagen</th>
              </tr>
            </thead>
            <tbody><Rows rowActionHandler={rowActionHandler} data={data} /></tbody>
          </table>
        }

        { total > 0 && <div className="d-flex justify-content-end">
          <Paginator currentPage={page} totalPages={total} onChange={onPageChange} />
        </div> }

      </div>
    </div>
  );
}

export default Products;
